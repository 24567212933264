
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        




























































































































































































.my-signin {
  padding-top: 0;
  padding-bottom: 0;
}

.fit {
  width: fit-content;
}

.intro {
  padding-right: col(2, 24);
  padding-left: col(2, 24);
}

.actions {
  flex: 1;
  border-left: 1px solid $c-gray-light;
}

.actions__cta,
.actions__login {
  position: relative;

  &::after {
    @include get-all-space;

    content: '';
    left: 100%;
  }
}

.action__login__connected {
  height: 100%;
  display: grid;
  align-content: center;
}

.actions__login {
  padding-right: col(2, 24);
  padding-left: col(2, 24);
  background: $c-gray-lightest;

  &::after {
    background: $c-gray-lightest;
  }

  @include mq(m) {
    padding-right: col(2, 12);
    padding-left: col(2, 12);
  }
}

.actions__login__footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.actions__login__footer__submit {
  @include loading($c-orange);
}

.actions__cta {
  align-items: flex-start;
  background: $c-yellow;
  border-bottom: 1px solid $c-white;
  display: flex;
  flex-direction: column;
  padding-right: col(2, 24);
  padding-left: col(2, 24);

  &::after {
    background: $c-yellow;
  }

  @include mq(m) {
    padding-right: col(2, 12);
    padding-left: col(2, 12);
  }

  .h4 {
    color: $c-white;
  }

  ::v-deep {
    .link .action__label {
      border-bottom: 1px solid $c-white;
      color: $c-white !important;
    }
  }
}

.intro__list__item {
  @extend %fw-medium;
}

.intro__advantages {
  @extend %fw-bold;
}
